console.log("init:device.js");

// console.log(myVariable, myFunction);

let device = {
    orientation: window.orientation,
    height: window.screen.height,
    width: window.screen.availWidth,
}


export {
    device,
}