<template>
  <div class="border-radius">
    <div class="margin-bottom"></div>
    <div class="flex-column margin-bottom" v-if="cui.show.conversation === true">
      <p class="fs-h4 uppercase clr-tertiary bold padding underline">messages</p>
      <!-- <div class="spacer"></div> -->
      <div class="fit-content pointer umderline margin border-radius padding-inline" v-for="(message, index) in messages" :key="index" @click="
      {
      messageIndex(index, message), setActive('edit_note');
    }
      ">
        <div class="message-item underline">
          <p class="fs-h6 clr-caution">{{ timer.time }}</p>
          <p class="fs-h5 margin-right">{{ message }}</p>
        </div>
      </div>
      <!--  -->
    </div>
  </div>
</template>

<script>
import Actions from "./Actions";

export default {
  name: "Messages",

  components: {
    Actions,
  },
  computed: {
    cui() {
      return this.$store.state.cui;
    },
    active() {
      return this.$store.state.active;
    },
    root() {
      return this.$store.state.root;
    },
    timer() {
      return this.$store.state.timer;
    },
    messages() {
      return this.$store.state.messages;
    },
  },

  methods: {
    messageIndex(payload, payload2) {
      this.$store.dispatch("messageIndex", payload, payload2);
    },
    setActive(payload) {
      this.$store.dispatch("setActive", payload);
      // this.$store.dispatch("init");
    },
  },
};
</script>

<style>
.messages {
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.message {
  width: fit-content;
  margin: var(--margin);
  color: var(--clr-light);
  border: var(--border);
  font-size: var(--fs-h5);
}

.message i {
  padding: 0;
  margin: 0;
}

.bubble {
  width: fit-content;
  display: flex;
  justify-content: space-between;
  /* box-shadow: var(--box-shadow); */
}

.message-item{
  display: flex;
  flex-direction: row;
}
</style>
