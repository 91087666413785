import axios from "axios";
// Set axios base URL based on environment
let URL = "";
if (process.env.NODE_ENV === "production") {
  URL = "https://reme.onrender.com";
} else {
  URL = "http://localhost";
}
export default axios.create({
  baseURL: URL,
});
