<template>
    <div id="init" class="init gap">
        <Title/>
        <InitContent/>
        <Copyright/>
    </div>
</template>

<script>

import Copyright from "../../../components/molecules/Copyright";
import InitContent from "../../../cui/init/InitContent.vue";
import Title from "../../../components/molecules/Title";

export default {
    name: "Init",

    components: {
        Title,
        InitContent,
        Copyright,
    },

    computed: {
        active() {
            return this.$store.state.active;
        },
        root() {
            return this.$store.state.root;
        },
    },

    methods: {
        setActive(payload) {
            this.$store.dispatch("setActive", payload);
        },
    },
};
</script>

<style>
.init {
    height: var(--vh);
    width: var(--vw);
    display: grid;
    grid-template-rows: auto 1fr auto;
    background-image: radial-gradient(ellipse at top left, red 20%, blue 80%);
    background: rgba(0, 0, 0, 0.9);
    padding-inline: calc(var(--fs)/3.6);
}


</style>