<template>
  <div class="padding-inline center pointer" @click="
        {
           setActive('about');
        }
      ">
    <div class="flex">
      <div class="flex">
        <p class="fs-h6 center clr-light">reme<span class="center bold fs-h5 clr-tertiary">3T</span></p>
        
      </div>
      <div>
        <p class="center fs-h2 clr-light"><span class="center bold fs-h2 clr-tertiary">&copy;</span>UI</p>
      </div>
      <span class="center clr-light">v1.8</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Logo",

  components: {},
  computed: {
    active() {
      return this.$store.state.active;
    },
    
  },

  methods: {
    setActive(payload) {
      this.$store.dispatch("setActive", payload);
    },
  },
};
</script>

<style>
.logo {
  /* display */
  display: flex;
  /* dimensions */
  width: fit-content;
  height: fit-content;
  /* positioning */
  /* appearance */
  background-image: var(--gradient);
  border: var(--border);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}
</style>
