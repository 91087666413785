<template>
  <!-- menu list -->
  <div class="menu overflow border-radius">
    <!-- <h6 class="uppercase clr-tertiary padding-inline">menu</h6> -->
    <div class="flex space-menu overflow" v-if="cui.show.menu === true">
      <!-- loop menu list -->

      <div class="menu-item pointer margin padding" v-for="(item, index) in active.menu" @click="setActive(item.title)">
        <div v-if=true>
          <!-- the menu item -->
          <div class="grid border-radius">

            <!-- menu icons -->
            <div class="flex">
              <div class=" flex fs-icon">
                <div class="fs-icon-small center">
                  <i class="material-icons clr-caution">{{ active.icon }}</i>
                </div>
                <i class="material-icons clr-light">{{ item.icon }}</i>
              </div>
            </div>
            <!-- menu title -->
            <div class="overflow">
              <p class=" fs-h5 capitalize clr-tertiary underline">
                {{ item.title }}
              </p>
            </div>
          </div>
        </div>
        <!-- menu information -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Menu",

  components: {},
  computed: {
    cui() {
      return this.$store.state.cui;
    },
    active() {
      return this.$store.state.active;
    },
  },

  methods: {
    setActive(payload) {
      this.$store.dispatch("setActive", payload);
      // this.$store.dispatch("init");
    },
  },
};
</script>

<style>
.menu {
  display: flex;
  flex-direction: column;
  overflow: auto;
}


.menu-fs p {
  font-size: calc(var(--fs)/2);
}
</style>
