import { device } from "../device/device";
import { enterFullscreen } from "../events/fullscreen/fullscreen";
// console.log(device);
console.log("orientation:", device.orientation);
console.log("height:", device.height);
console.log("width:", device.width);


console.log("init:events.js");
const app = document.getElementById('app');

// listen for orientation change 
app.addEventListener('orientationchange', (event)=>{
  console.log("orientationchange event");
});

app.addEventListener('click', (event)=>{
  console.log("click event");
  // enterFullscreen();
});

// listen for dblclick
app.addEventListener('dblclick', (event)=>{
  console.log("dblclick event");
});

// update vh
function updateVHVariable() {
  // Set --vh CSS variable based on dynamic screen availHeight
  // console.log(window.screen.availHeight);
  document.documentElement.style.setProperty('--vh', window.screen.availHeight + 'px');
}

// Initial update
updateVHVariable();

// Add an event listener for window resize
window.addEventListener('resize', updateVHVariable);

// Add an event listener for when the virtual keyboard is opened
window.addEventListener('resize', function() {
  
  if (document.activeElement.tagName === 'INPUT' || document.activeElement.tagName === 'TEXTAREA') {
    updateVHVariable();
  }
});

// Client-side JavaScript
function sendPing() {
  // Send a ping request to the server
  // You can use fetch, XMLHttpRequest, or any other method to send the request
  fetch('https://reme.onrender.com/ping', {
      method: 'GET'
  })
  .then(response => {
      if (response.ok) {
          console.log('Ping successful');
      } else {
          console.error('Ping failed');
      }
  })
  .catch(error => {
      console.error('Error sending ping:', error);
  });
}

// Send the initial ping when the client loads
sendPing();

// Send a ping every 180000 miliseconds, every 180 seconds (3 minutes)
setInterval(sendPing, 180000);
