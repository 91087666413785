<template>
  <!-- device properties -->
  <div class="flex" v-if="true">
    <div>
      <p class="fs-h5">Device Properties {{}}</p>
    </div>
  </div>
</template>

<script>
// import handle from "../../utilities/js/device/device";
// console.log(handle);
export default {
  data() {
    return {
      // viewport: {
      //   orientation: handle.device.orientation,
      //   height: handle.device.height,
      //   width: handle.device.height,
      // },
    };
  },
  computed: {
    cui() {
      return this.$store.state.cui;
    },
    active() {
      return this.$store.state.active;
    },
    root() {
      return this.$store.state.root;
    },

  },
  methods: {
    setActive(payload) {
      this.$store.dispatch("setActive", payload);
      // this.$store.dispatch("init");
    },
  },
};
</script>

<style></style>
