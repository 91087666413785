<template>
  <div class="flex space-between border-radius-top pointer-none">
    <div>
      <span class="clr-tertiary pointer-none" @click="
          {
            setActive('copyright');
          }
        ">
      2024 &copy; All Rights Reserved.
    </span>
    </div>
    <div>
      <span class="uppercase clr-tertiary pointer-none" @click="
          {
            setActive('simulation');
          }
        ">
      3DSim<span class="clr-secondary">ulation</span>
    </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Icons",

  components: {},
  computed: {
    icons() {
      return this.$store.state.icons;
    },
  },

  methods: {
    setActive(payload) {
      this.$store.dispatch("setActive", payload);
    },
  },
};
</script>
