<template>
    <div class="init-content padding-div gap">
        <div class="init-display gap border-radius">
            <div>
                <div v-if="true">
                    <p class="fs-h2 bold uppercase clr-tertiary underline">{{ active.title }}</p>
                    <p class="fs-h4 clr-light">
                        {{ active.information }}
                    </p>
                    <!-- <div class="margin-bottom"></div> -->
                    <p class="hide fs-h5">{{ users }}</p>
                </div>
                <!--  -->
                <div v-else class="grid-col-4 gap padding-div gradient-2 border-radius">
                    <div class="gradient">
                        <p class="fs-h5 underline"><span class="fs-h3 clr-tertiary underline">Qs:</span> {{
                    root.occurrenceQs }}</p>
                        <p class="fs-h5 underline"><span class="fs-h3 clr-tertiary underline">Rs:</span> {{
                    root.occurrenceRs }}</p>
                        <p class="fs-h5 underline"><span class="fs-h3 clr-tertiary underline">Cs:</span> {{
                    root.occurrenceCs }}</p>
                        <p class="fs-h5 underline"><span class="fs-h3 clr-tertiary underline">Xs:</span> {{
                    root.occurrenceXs }}</p>

                    </div>
                    <div class="hide">
                        <p class="fs-h5">Analysis</p>
                        <p class="fs-h4">{{ root.analysis }}</p>
                    </div>
                    <div class="gradient">
                        <p class="fs-h5 uppercase clr-secondary">Tier 1 Qs</p>
                        <div v-for="item in root.questions">
                            <p class="fs-h5 underline">{{ item }}</p>
                        </div>
                    </div>
                    <div class="gradient">
                        <p class="fs-h5 uppercase clr-secondary">Tier 2 Rs</p>
                        <div v-for="item in root.responses">
                            <p class="fs-h5 underline">{{ item }}</p>
                        </div>
                    </div>
                    <div class="gradient">
                        <p class="fs-h5 uppercase clr-secondary">Tier 3 Xs</p>
                        <div v-for="item in root.context">
                            <p class="fs-h5 underline">{{ item }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- display slides -->
            <div class="init-display-slides gap border-radius-div padding-div">
                <div class="bgr-img box-shadow pointer invert" @click="setActive('option 1')">
                    <p class="fs-h5 bold underline">{{ users[0].name }}</p>
                    <p class="fs-h5">{{ users[0].email }}</p>
                    <p class="gradient fs-h4 clr-tertiary">{{ root.path }}</p>
                </div>
                <div class="bgr-img box-shadow pointer" @click="setActive('option 2')">
                    <p class="fs-h5 bold underline">{{ users[1].name }}</p>
                    <p class="fs-h5">{{ users[1].email }}</p>
                </div>
                <div class="bgr-img box-shadow pointer" @click="setActive('option 3')">
                    <p class="fs-h5 bold underline">User 3</p>
                    <p class="fs-h5">example@gmail.com</p>

                </div>

            </div>
        </div>
        <div class="grid-col-2 border-radius">
            <Messages />
            <Information />
        </div>
        <div class="init-interaction gap border-radius-top">
            <Menu />
            <Input />
        </div>
    </div>
</template>

<script>
// init components
import Messages from "../../components/molecules/Messages.vue"
import Information from "../../components/molecules/Information.vue"
import Menu from "../../components/molecules/Menu.vue"
import Input from "../../components/molecules/Input.vue"

// init data
import sampleData from "../../../../data/sampleData.json";

export default {
    name: "InitContent",

    data() {
        return {
            users: sampleData.users,
        }
    },

    components: {
        Messages,
        Information,
        Menu,
        Input,
    },

    computed: {
        active() {
            return this.$store.state.active;
        },
        root() {
            return this.$store.state.root;
        },
        cui() {
            return this.$store.state.cui;
        },
    },

    methods: {
        setActive(payload) {
            this.$store.dispatch("setActive", payload);
        },
    },
};
</script>

<style>
.init-content {
    height: 100%;
    display: grid;
    grid-template-rows: .72fr .27fr auto;
}

.init-display {
    display: grid;
    grid-template-rows: .63fr .36fr;
}

.init-display-slides {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.init-interaction {
    display: grid;
    grid-template-rows: 1fr auto;
}
</style>