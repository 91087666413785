<template>
  <div  class="border-radius" >
    <textarea
      class="padding"
      name=""
      id=""
      v-model="message"
      :placeholder="placeholder"
      v-on:keyup="keyUp()"
      v-on:keyup.enter="processMessage($event)"
    ></textarea>
  </div>
</template>

<script>
export default {
  name: "Input",

  components: {},
  data() {
    return {
      message: "",
    };
  },
  computed: {
    show() {
      return this.$store.state.show;
    },
    placeholder() {
      return this.$store.state.placeholder;
    },
    action() {
      return this.$store.state.action;
    },
    active() {
      return this.$store.state.active;
    },
  },

  methods: {
    processMessage(event) {
      if (this.$store.state.messages.length === 0) {
        this.$store.dispatch("initialize");
      }
      event.preventDefault();
      this.$store.dispatch("processMessage", this.message);
      this.message = "";
    },
    setActive(payload) {
      this.$store.dispatch("setActive", payload);
    },
    keyUp() {
      // console.log("keyUp event:" + this.message);
    },
  },
};
</script>

<style>
textarea {
  resize: none;
  font-family: FontFamily !important;
  font-size: var(--fs-h5) !important;
  font-weight: unset !important;
  color: var(--clr-light);
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  /* padding: calc(var(--fs)); */
  /* padding-top: calc(var(--fs)); */
  /* padding-left: calc(var(--fs)); */
  overflow: hidden;
  background: transparent;
  /* box-shadow: var(--box-shadow); */
}
</style>
