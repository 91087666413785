<template>
  <div class="title border-radius-bottom">
    <!-- logo -->
    <Logo />
    <!-- device properties -->
    <div class="">
      <!-- <Device /> -->
    </div>
    <!-- visibility -->
    <div class="center pointer-none" v-if="cui.show.visibility === true" @click="setActive('status')">
      <div class="flex">
        <span class="fs-h5 clr-secondary uppercase padding-inline pointer padding-inline" @click="setActive('session')">session</span>
        <span class="fs-h5 clr-success uppercase padding-inline pointer" @click="setActive('dashboard')">online</span>
        <span class="fs-h5 clr-danger uppercase padding-inline pointer" @click="setActive('dashboard')">private</span>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "./Logo.vue";
import Device from "./Device.vue";

export default {
  name: "Title",
  components: {
    Logo,
    Device,
  },
  computed: {
    cui() {
      return this.$store.state.cui;
    },
    active() {
      return this.$store.state.active;
    },
  },

  methods: {
    setActive(payload) {
      this.$store.dispatch("setActive", payload);
    },
  },
};
</script>

<style>
.title {
  display: grid;
  grid-template-columns: auto 1fr auto;
}
</style>
