<template>
  <div class="information overflow overflow-div gradient-2 padding underline" v-if="cui.show.information === true">
    <div class="flex fs-icon-medium">
      <i class="material-icons">{{ active.icon }}</i>
      <p class="fs-h3 center capitalize">{{ active.title }}</p>
    </div>
    <div class="">
      <p class="fs-h5 clr-secondary">{{ active.information }}</p>
    </div>
    <div class="flex">
      <div class="flex">
        <p class="fs-h6 clr-tertiary ">reme.onrender.com</p>
        <p class="fs-h6 clr-tertiary padding-inline">Est. 2024</p>
      </div>
      <!--  -->
      <div class="center fs-icon-small">
        <i class="material-icons clr-caution">info</i>  
      </div>
    </div>
  </div>
</template>

<script>
import Messages from "./Messages.vue";
import TextArea from "./TextArea.vue";
export default {
  name: "Information",

  components: {
    Messages,
    TextArea,
  },
  computed: {
    cui() {
      return this.$store.state.cui;
    },
    active() {
      return this.$store.state.active;
    },

  },
  methods: {
    setActive(payload) {
      this.$store.dispatch("setActive", payload);
      // this.$store.dispatch("init");
    },
  },
};
</script>

<style>
.information {
  display: grid;
  grid-template-rows: auto 1fr auto;
}
</style>
