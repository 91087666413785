import Vue from "vue";
import { createApp } from "vue";
import { store } from "./store/store";
import App from "./App.vue";

// Custom js
import "./utilities/js/customJS";

// Custom directives

const app = createApp(App);

app.use(store);
app.mount("#app");
