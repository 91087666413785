<template>
  <div class="actions" v-if="show.actions === true">
    <div v-for="action in active.actions">
      <i class="material-icons" @click="setActive(action)">{{ action }}</i>
    </div>
  </div>
</template>

<script>
export default {
  name: "Actions",
  components: {},
  computed: {
    show() {
      return this.$store.state.show;
    },
    active() {
      return this.$store.state.active;
    },
  },

  methods: {
    setActive(payload) {
      this.$store.dispatch("setActive", payload);
    },
  },
};
</script>

<style>
.actions {
  display: flex;
}

.actions i {
  font-size: calc(var(--fs) / 4);
  padding: calc(var(--fs) / 8);
}

.actions i:last-child {
  margin-right: 0;
}
</style>
