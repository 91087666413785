<template>
  <div class="input underline" v-if="cui.show.input === true">
    <div class="">
      <!-- <div class="spacer"></div> -->
      <TextArea />
    </div>
    <!-- side icons -->
    <div class="center">
      <div class="fs-icon padding-inline border-radius">
        <!--  -->
        <!-- <i style="padding:.63rem;" class="material-icons clr-secondary pointer" @click="setActive('mic')">mic</i> -->
        <i class="material-icons clr-secondary pointer" @click="setActive('keyboard')">keyboard</i>
        <i class="material-icons clr-light pointer" @click="setActive('dashboard')">dashboard</i>
      </div>
    </div>
  </div>
</template>

<script>
import TextArea from "./TextArea.vue";

export default {
  name: "Input",

  components: {
    TextArea,
  },
  data() {
    return {
      message: "",
    };
  },
  computed: {
    cui() {
      return this.$store.state.cui;
    },
    // 
    show() {
      return this.$store.state.show;
    },
    placeholder() {
      return this.$store.state.placeholder;
    },
    action() {
      return this.$store.state.action;
    },
    active() {
      return this.$store.state.active;
    },
    input() {
      return this.$store.state.input;
    },
  },

  methods: {
    processMessage(event) {
      if (this.$store.state.messages.length === 0) {
        this.$store.dispatch("initialize");
      }
      event.preventDefault();
      this.$store.dispatch("processMessage", this.message);
      this.message = "";
    },
    setActive(payload) {
      this.$store.dispatch("setActive", payload);
    },
  },
};
</script>

<style>
.input {
  display: grid;
  grid-template-columns: 1fr auto;;
}

</style>
