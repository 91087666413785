import { createStore } from "vuex";
import axios from "../utilities/js/axios/axios";
import events from "../utilities/js/events/events";
export const store = createStore({
  namespace: true,
  //
  modules: {
    // stateModule,
  },
  state: {
    p: {
      pMystery: "100.999",
      pMirror: "m0",
      pReflection: "r0",
      pStructure: "0 1 0:0 1 0 0",
      pForm: "-0 0 : 0 1 0 : 0 1 0 0",
      PEntity: "-0 0 : 0 1 0 : 0 1 0 0",
    },
    // cui root
    cui: {
      actionIcon: "mic",
      inputIcon: "keyboard",
      show: {
        layout: false,
        main: true,
        device: true,
        visibility: true,
        sessions: true,
        icons: true,
        engineeringIcon: false,
        content: true,
        information: true,
        stats: true,
        conversation: true,
        menu: true,
        screen: true,
        slides: true,
        footer: true,
        input: true,
      },
    },
    // active root
    active: {
      icons: [
        { icon: "person", title: "account" },
        { icon: "apps", title: "apps" },
        { icon: "miscellaneous_services", title: "services" },
        { icon: "storefront", title: "market" },
        { icon: "settings", title: "settings" },
      ],
      action: "select action",
      input: { tite: "keyboard" },
      icon: "dashboard",
      title: "dashboard",
      information:
        "Welcome! We're thrilled to have you join us. Before you dive into the fantastic features, we kindly ask you to take a moment to review and accept our Terms and Agreements. Your cooperation ensures a seamless experience for everyone. Thank you!",
      actions: ["edit_note"],
      menu: [
        { icon: "person", title: "account" },
        { icon: "apps", title: "apps" },
        { icon: "miscellaneous_services", title: "services" },
        { icon: "shopping_bag", title: "store" },
        { icon: "settings", title: "settings" },
      ],
    },
    // ratios etc
    ratios: {
      one: "1:1",
      two: "1.2",
      three: "1:3",
      four: "1:4",
      five: "1:5",
      six: "1:6",
      seven: "1:7",
      eight: "1:8",
      nine: "1:9",
      zero: "1:0",
      negOne: "1:-1",
      negTwo: "1.-2",
      negThree: "1:-3",
      negFour: "1:-4",
      negFive: "1:-5",
      negSix: "1:-6",
      negSeven: "1:-7",
      negEight: "1:-8",
      negNine: "1:-9",
      negZero: "1:-0",
    },
    zoom: 100,
    timer: {
      time: "",
      getTime() {
        let date = new Date();
        let time = date.getHours();
        time += ":" + date.getMinutes();
        return `${time}`;
      },
    },
    status: { text: "Standing by" },
    show: { icons: false },
    cards: { items: { id: "1", id: "2", id: "3" } },
    placeholder: "Standing by",
    message: "",
    messages: [],
    messagesLength: 0,
    messIndex: "",
    action: "conversation",
    icons: [
      {
        icon: "dashboard",
        title: "dashboard",
      },
    ],
    pulseMessage: false,
    input: { title: "keyboard" },

    root: {},
    show: {
      actions: false,
    },
  },

  methods: {
    clipArray(array, limit) {
      while (array.length > limit) {
        array.shift();
      }
      return array;
    },
  },
  actions: {
    async initialize({ commit, state }) {
      state.placeholder = "Initializing...";
      await axios
        .get("api/conversation")
        .then((response) => {
          state.placeholder = "Initializing...";
        })
        .catch((err) => console.log("find err: " + err));
    },

    async processMessage({ commit, state }, payload) {
      if (payload.trim() !== "") {
        payload = payload.trim().toLowerCase();
        state.messages.push(payload);
        state.placeholder = "Processing...";
        //
        while (state.messages.length > 3) {
          state.messages.shift();
        }
        state.messagesLength = state.messages.length;
        // simulate empty param
        let param = payload;

        // console.log("conversation:" + param);
        await axios
          .get("api/conversation/" + param)
          .then((response) => {
            // console.log(response.data.feedback);
            state.messages.push(response.data.feedback.response);
            state.root = response.data.feedback.root;
            state.timer.time = state.timer.getTime();
            while (state.messages.length > 3) {
              state.messages.shift();
            }
          })
          .catch((err) => console.log("find err: " + err));

        state.placeholder = "Type message";
        state.status.text = state.placeholder;
      }
    },

    init() {
      if (state.messages.length === 0) {
        console.log("init things...");
      }
    },
    // messages
    messageIndex(index) {
      console.log("index:" + index);
    },
    // set active
    setActive({ commit, state }, payload) {
      console.log("setActive('" + payload + "')");
      if (payload === "statistics") {
        state.active.action = "display statistics";
        state.active.icon = "query_stats";
        state.active.title = "statistics";
        state.active.information =
          "Unlock Insights with Statistics! Dive into data-driven understanding. From trends to analysis, explore the numbers shaping your world. Empower decision-making and stay informed. Your statistics, your advantage. ";
        state.active.menu = [
          { icon: "query_stats", title: "data usage" },
          { icon: "query_stats", title: "power usage" },
          { icon: "query_stats", title: "performance" },
          { icon: "query_stats", title: "analyses" },
        ];
      } else if (payload === "grid_4x4") {
        // TODO
        // create grid
      } else if (payload === "information") {
        state.active.action = "relevant information";
        state.active.icon = "info";
        state.active.title = "information";
        state.active.information =
          "Information Oasis! Dive into a wealth of knowledge. Explore, learn, and stay informed. Your go-to source for insights and updates. Empower your mind with information. Explore today! ";
        state.active.menu = [
          { icon: "info", title: "latest" },
          { icon: "info", title: "about" },
        ];
      } else if (payload === "about") {
        state.active.action = "relevant about";
        state.active.icon = "info";
        state.active.title = "about";
        state.active.information =
          "Information Oasis! Dive into a wealth of knowledge. Explore, learn, and stay informed. Your go-to source for insights and updates. Empower your mind with information. Explore today! ";
        state.active.menu = [
          { icon: "info", title: "about" },
          { icon: "info", title: "manual" },
          { icon: "info", title: "feedback" },
          { icon: "info", title: "funding" },
        ];
      } else if (payload === "funding") {
        state.active.action = "funding";
        state.active.icon = "info";
        state.active.title = "funding";
        state.active.information =
          "Our project is seeking funding to fuel innovation and growth. Join us on this journey, invest in success!";
        state.active.menu = [{ icon: "info", title: "info" }];
      } else if (payload === "manual") {
        state.active.action = "manual";
        state.active.icon = "info";
        state.active.title = "manual";
        state.active.information =
          "Dive into our comprehensive user manual for step-by-step guidance. Master the features and elevate your experience.";
        state.active.menu = [{ icon: "info", title: "info" }];
      } else if (payload === "feedback") {
        state.active.action = "feedback";
        state.active.icon = "info";
        state.active.title = "feedback";
        state.active.information =
          "We value your input! Share your thoughts and suggestions with us. Your feedback is crucial in shaping a better experience.";
        state.active.menu = [{ icon: "info", title: "info" }];
      } else if (payload === "copyright") {
        state.active.action = "copyright";
        state.active.icon = "info";
        state.active.title = "copyright";
        state.active.information =
          "App Copyright Protection! Our commitment to originality and innovation. Respect the app's copyright. Together, we uphold creativity and ensure a secure digital environment. ";
        state.active.menu = [{ icon: "info", title: "info" }];
      } else if (payload === "simulation") {
        state.active.action = "simulation";
        state.active.icon = "info";
        state.active.title = "simulation";
        state.active.information =
          "Delve into a world of virtual experiences. Gain knowledge, refine skills, and explore possibilities. Your simulation, your journey. Discover, learn, thrive! ";
        state.active.menu = [{ icon: "info", title: "info" }];
      } else if (payload === "dashboard") {
        state.active.action = "session";
        state.active.icon = payload;
        state.active.title = "dashboard";
        state.active.information =
          "Unleash the power of A.I. at your fingertips. Intelligent, intuitive, and ready to revolutionize your experience. Let's redefine the future together. Select one of the options to get started.";
        state.active.menu = [
          { icon: "person", title: "account" },
          { icon: "apps", title: "apps" },
          { icon: "miscellaneous_services", title: "services" },
          { icon: "shopping_bag", title: "store" },
          { icon: "settings", title: "settings" },
        ];
      } else if (payload === "axis") {
        state.active.action = "design";
        state.active.icon = "info";
        state.active.title = "design";
        state.active.information =
          " Design Brilliance Unleashed! Explore our intuitive design tool – where creativity meets functionality. Craft visually stunning experiences effortlessly. Your vision, our canvas. Design, delight, deliver!";
        state.active.menu = [{ icon: "info", title: "info" }];
      } else if (payload === "sort") {
        state.active.action = "sort";
        state.active.icon = "sort";
        state.active.title = "sort";
        state.active.information =
          "Easily sort and find content that matters to you. From A to Z or by relevance, tailor your view. Effortless navigation, personalized discovery. Explore smarter, not harder.";
        state.active.menu = [{ icon: "info", title: "info" }];
      } else if (payload === "back") {
        state.active.action = "navigate back";
        state.active.icon = "arrow_back";
        state.active.title = "back";
        state.active.information =
          " Return to the previous menu effortlessly. Your journey, your pace. Explore without limits. Simply go back and continue your adventure!";
        state.active.menu = [{ icon: "info", title: "info" }];
      } else if (payload === "status") {
        state.active.action = "status information";
        state.active.icon = "info";
        state.active.title = "status";
        state.active.information =
          "You're securely logged in. Your personalized experience awaits. Explore, connect, and enjoy hassle-free navigation. Your journey begins here. Stay logged in for seamless adventures!";
        state.active.menu = [{ icon: "info", title: "info" }];
      } else if (payload === "policy") {
        state.active.action = "see policies";
        state.active.icon = "policy";
        state.active.title = "policy";
        state.active.information =
          "Policy Central! Your guide to clarity and fairness. Explore our policies, ensuring transparency, trust, and a secure user experience. Empowering you with knowledge, every step of the way.";
        state.active.menu = [
          { icon: "policy", title: "privacy policy" },
          { icon: "policy", title: "terms of service" },
          { icon: "policy", title: "cookie policy" },
        ];
      } else if (payload === "remove") {
        console.log(this._state.data.messages);
        let param =
          this._state.data.messages[this._state.data.messages.length - 1];
        axios
          .delete("api/conversation/" + param)
          .then((response) => {
            // console.log(response.data.feedback);
            state.messages.push(response.data.feedback.response);
            state.root = response.data.feedback.root;
            state.timer.time = state.timer.getTime();
            while (state.messages.length > 3) {
              state.messages.shift();
            }
          })
          .catch((err) => console.log("find err: " + err));
        this._state.data.messages.pop();
      } else if (payload === "add") {
        state.active.action = "add";
        state.active.icon = payload;
        state.active.title = "Add";
        state.active.information = "Interact, Recommend, advertise and more";
        state.active.actionIcon = "edit_note";
        state.active.menu = [
          { icon: "edit", title: "edit" },
          { icon: "attachment", title: "attachment" },
          { icon: "ads_click", title: "adverts" },
          { icon: "play_circle", title: "run" },
        ];
      } else if (payload === "edit_note") {
        state.active.action = "edit_note";
        state.active.icon = payload;
        state.active.action = "associate";
        state.active.title = "interact";
        state.active.information =
          "Interact with the selected message. You can edit, delete, recommend, translate or advertise on a message. This will earn you coins that you can then use in the store for purchases and payments.";
        state.active.actionIcon = "edit_note";
        state.active.menu = [
          { icon: "edit", title: "edit" },
          { icon: "attachment", title: "attachment" },
          { icon: "recommend", title: "recommend" },
          { icon: "play_circle", title: "run" },
        ];
      } else if (payload === "run") {
        state.cui.actionIcon = "check";
        //
        state.active.action = "run";
        state.active.icon = "play_circle";
        state.active.title = "run";
        state.active.information =
          "Pick a word and match it with a menu option";
        state.active.actionIcon = "run";
        state.active.menu = [{ icon: "info", title: "select word" }];
      } else if (payload === "account") {
        state.active.action = "personal";
        state.active.action = "personal";
        state.active.icon = "person";
        state.active.title = "account";
        state.active.information =
          "Manage, secure, and personalize your profile effortlessly. From security settings to personal preferences, it's all here. Your digital space, your way.";
        state.active.menu = [
          { icon: "login", title: "login" },
          { icon: "engineering", title: "engineering" },
          { icon: "remember_me", title: "session" },
        ];
      } else if (payload === "reset") {
        state.active.action = "reset credentials";
        state.active.icon = "lock_reset";
        state.active.title = "reset";
        state.active.information =
          "Reset and secure your account! Follow the link to easily reset your credentials. Keep your account safe and continue with confidence.";

        state.active.menu = [{ icon: "info", title: "info" }];
      } else if (payload === "register") {
        state.active.action = "register account";
        state.active.title = "register";
        state.active.icon = "how_to_reg";
        state.active.information =
          "Unlock a world of possibilities! Register an account today for exclusive access, personalized features, and a seamless user experience. Join us now!";
        state.active.menu = [{ icon: "info", title: "info" }];
      } else if (payload === "profile") {
        state.active.action = "manage profile";
        state.active.icon = "manage_accounts";
        state.active.title = "profile";
        state.active.information =
          " Your Digital Persona! Dive into the Personal Profile menu – where you shape your digital identity. From details to preferences, make it uniquely yours. Explore, customize, and own your profile today!";
        state.active.menu = [
          { icon: "manage_accounts", title: "privacy" },
          { icon: "manage_accounts", title: "information" },
          { icon: "manage_accounts", title: "notifications" },
          { icon: "manage_accounts", title: "accounts" },
          { icon: "manage_accounts", title: "payments" },
          { icon: "manage_accounts", title: "activity" },
          { icon: "manage_accounts", title: "delete" },
        ];
      } else if (payload === "contact") {
        state.active.action = "manage contact information";
        state.active.icon = "contact_page";
        state.active.title = "contact";
        state.active.information =
          " Personal Contact Information Hub! Safely manage and update your details. Your privacy, our priority. Stay connected seamlessly. Update, secure, and personalize your contact information effortlessly. Your digital space, your rules.";
        state.active.menu = [{ icon: "info", title: "info" }];
      } else if (payload === "wallet") {
        state.active.action = "manage wallet";
        state.active.icon = "account_balance_wallet";
        state.active.title = "wallet";
        state.active.information =
          "Take control of your finances with our comprehensive management tools. Budget, track, and plan for a secure future. Your financial success starts here. Dive into smart money management now";
        state.active.menu = [
          { icon: "account_balance_wallet", title: "wallet 1" },
          { icon: "account_balance_wallet", title: "wallet 2" },
          { icon: "account_balance_wallet", title: "wallet 3" },
          { icon: "account_balance_wallet", title: "wallet 4" },
        ];
      } else if (payload === "badge") {
        state.active.action = "badge";
        state.active.icon = "badge";
        state.active.title = "badge";
        state.active.information =
          "Elevate your status! Explore the Badge Level Menu to track your achievements and climb higher. Unleash new privileges as you advance. Level up now!";
        state.active.menu = [
          { icon: "remember_me", title: "member" },
          { icon: "remember_me", title: "guest" },
        ];
      } else if (payload === "login") {
        state.active.action = "login";
        state.active.icon = "login";
        state.active.title = "login";
        state.active.information =
          "Log in to your account and dive into a world personalized just for you. Your journey awaits!";
        state.active.menu = [
          { icon: "password", title: "password" },
          { icon: "pattern", title: "pattern" },
          { icon: "fingerprint", title: "fingerprint" },
          { icon: "lock_reset", title: "reset" },
          { icon: "how_to_reg", title: "register" },
        ];
      } else if (payload === "group") {
        state.active.action = "interact with friends";
        state.active.icon = payload;
        state.active.title = "friends";
        state.active.information =
          "Connect with friends, share laughs, and stay in sync. Your space for conversations, jokes, and unforgettable moments. Start chatting, stay connected. Let the good times roll! ";
        state.active.menu = [
          { icon: "bookmarks", title: "favorite contacts" },
          { icon: "search", title: "search" },
          { icon: "add", title: "create contact" },
        ];
      } else if (payload === "groups") {
        state.active.action = "interact with communities";
        state.active.icon = "groups";
        state.active.title = "communities";
        state.active.information =
          "Engage, share, and catch up with like-minded souls. Your space for lively discussions and connecting with diverse communities. Join the conversation and make every moment count! ";
        state.active.menu = [
          { icon: "list", title: "communities" },
          { icon: "search", title: "search" },
          { icon: "add", title: "create community" },
        ];
      } else if (payload === "notifications") {
        state.active.action = "stay updated";
        state.active.icon = "notifications";
        state.active.title = "notifications";
        state.active.information =
          " Stay Informed, Stay Connected! Manage your notifications to receive updates, alerts, and personalized messages. Tailor your experience and never miss a moment. Your notifications, your way.";
        state.active.menu = [
          { icon: "notifications_active", title: "active" },
          { icon: "notification_important", title: "important" },
          { icon: "add_alert", title: "alert" },
          { icon: "notifications_paused", title: "paused" },
        ];
      } else if (payload === "settings") {
        state.active.action = "arrow_back";
        state.active.icon = "settings";
        state.active.title = "settings";
        state.active.information =
          "Personalize themes, tweak preferences, and ensure your OS dances to your tune. Explore endless customization at your fingertips.";
        state.active.menu = [
          { icon: "devices_other", title: "device" },
          { icon: "cell_tower", title: "connections" },
          { icon: "display_settings", title: "display" },
          { icon: "schedule", title: "clock" },
          { icon: "accessible", title: "accessibility" },
        ];
      } else if (payload === "connections") {
        state.active.action = "manage connections";
        state.active.icon = "cell_tower";
        state.active.title = "connections";
        state.active.information =
          " Explore a web of seamless connections. From WiFi wizardry to network mastery, stay linked effortlessly. Elevate your digital experience. Connect, communicate, conquer! ";
        state.active.menu = [
          { icon: "wifi", title: "wifi" },
          { icon: "signal_cellular_alt", title: "cellular" },
          { icon: "bluetooth", title: "bluetooth" },
          { icon: "lan", title: "lan" },
        ];
      } else if (payload === "wifi") {
        state.active.action = "configure";
        state.active.icon = "wifi";
        state.active.title = "wifi";
        state.active.information =
          "Connect to the world at the speed of light. Explore the WiFi menu to link seamlessly. Your gateway to fast, reliable connections. Stay connected, roam freely.";
        state.active.menu = [{ icon: "info", title: "info" }];
      } else if (payload === "cellular") {
        state.active.action = "configure";
        state.active.icon = "signal_cellular_alt";
        state.active.title = "cellular";
        state.active.information =
          "Explore seamless connectivity with our versatile cellular network options. Stay connected, your way!";
        state.active.menu = [{ icon: "info", title: "info" }];
      } else if (payload === "lan") {
        state.active.action = "configure";
        state.active.icon = "lan";
        state.active.title = "lan";
        state.active.information =
          "Boost productivity with our fast, reliable local LAN network. Stay connected effortlessly!";
        state.active.menu = [{ icon: "info", title: "info" }];
      } else if (payload === "bluetooth") {
        state.active.action = "manage bluetooth connections";
        state.active.icon = "bluetooth";
        state.active.title = "bluetooth";
        state.active.information =
          " Wirelessly connect, share, and amplify your experience. Dive into the Bluetooth menu, where devices sync effortlessly. Cut the cords, elevate the connections. Ready, set, Bluetooth!";
        state.active.menu = [{ icon: "info", title: "info" }];
      } else if (payload === "accessibility") {
        state.active.action = "manage accessibility";
        state.active.icon = "accessible";
        state.active.title = "accessibility";
        state.active.information =
          "Enhance inclusivity. Explore our accessible menu for a user-friendly, personalized experience. #AccessibilityMatters";
        state.active.menu = [
          { icon: "accessible", title: "text size" },
          { icon: "accessible", title: "contrast" },
          { icon: "accessible", title: "reader" },
          { icon: "accessible", title: "descriptions" },
          { icon: "accessible", title: "captioning" },
          { icon: "accessible", title: "recognition" },
          { icon: "accessible", title: "help" },
        ];
      } else if (payload === "device") {
        state.active.action = "configure";
        state.active.icon = "devices_other";
        state.active.title = "device";
        state.active.information =
          "Efficient device control. Manage, optimize, and streamline with our intuitive system management tools. Elevate performance effortlessly.";
        state.active.menu = [
          { icon: "power_settings_new", title: "power" },
          { icon: "refresh", title: "refresh" },
          { icon: "system_update_alt", title: "update" },
        ];
      } else if (payload === "refresh") {
        location.reload();
      } else if (payload === "calendar") {
        state.active.action = "calendar";
        state.active.icon = "calendar_month";
        state.active.title = "calendar";
        state.active.information =
          "Effortlessly organize your days. Simplify life with our intuitive calendar management. Stay on track!";
        state.active.menu = [{ icon: "info", title: "info" }];
      } else if (payload === "clock") {
        state.active.action = "clock";
        state.active.icon = "schedule";
        state.active.title = "clock";
        state.active.information =
          "Customize time seamlessly. Explore and tailor clock settings for a personalized and efficient experience. #TimeWellSpent";
        state.active.menu = [
          { icon: "alarm", title: "alarm" },
          { icon: "timer", title: "timer" },
        ];
      } else if (payload === "alarm") {
        state.active.action = "manage alarms";
        state.active.icon = "alarm";
        state.active.title = "alarm";
        state.active.information =
          "Enhance your time management. Stay on top of your tasks, improve your productivity.";
        state.active.menu = [{ icon: "info", title: "info" }];
      } else if (payload === "update") {
        state.active.action = "update system";
        state.active.icon = "update";
        state.active.title = "update";
        state.active.information =
          "Enhance stability and features. Update your system firmware for improved performance and security. Stay current!";
        state.active.menu = [{ icon: "info", title: "info" }];
      } else if (payload === "power") {
        state.active.action = "power settings";
        state.active.icon = "power_settings_new";
        state.active.title = "power";
        state.active.information =
          "Explore efficient power choices in our menu—customize, conserve, and enhance performance effortlessly.";
        state.active.menu = [
          { icon: "mode_standby", title: "standby" },
          { icon: "settings_power", title: "power off" },
        ];
      } else if (payload === "standby") {
        state.active.action = "standing by";
        state.active.icon = "mode_standby";
        state.active.title = "standby";
        state.active.information =
          "Effortless energy conservation! Activate standby mode to save power without losing quick access. Efficiency meets convenience.";
        state.active.menu = [{ icon: "info", title: "info" }];
      } else if (payload === "power off") {
        state.active.action = "shutt down";
        state.active.icon = "settings_power";
        state.active.title = "power off";
        state.active.information =
          "Power down for peace. Safely shut off your device, conserving energy and ensuring a refreshed start.";
        state.active.menu = [{ icon: "info", title: "info" }];
      } else if (payload === "display") {
        state.active.action = "show settings options";
        state.active.icon = "display_settings";
        state.active.title = "display";
        state.active.information =
          "Explore the Display Settings menu to customize your view. From resolution to brightness, tailor your screen for optimal delight. Your display, your vision.";
        state.active.menu = [
          { icon: "fullscreen", title: "fullscreen" },
          { icon: "hd", title: "resolution" },
          { icon: "zoom_in", title: "zoom" },
          { icon: "dark_mode", title: "mode" },
          { icon: "settings_brightness", title: "brightness" },
          { icon: "wallpaper", title: "background" },
        ];
      } else if (payload === "fullscreen") {
        // Set app full screen
        var elem = document.getElementById("app");
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
          elem.elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen();
        }
      } else if (payload === "resolution") {
        state.active.action = "device resolution";
        state.active.icon = "devices";
        state.active.title = "resolution";
        state.active.information =
          " Refine Your View! Tailor device resolution for a crisp, personalized experience. Your screen, your clarity.";
        state.active.menu = [{ icon: "info", title: "info" }];
      } else if (payload === "zoom") {
        state.active.action = "device zoom";
        state.active.icon = "zoom_in";
        state.active.title = "zoom";
        state.active.information =
          "Zoom In, Enhance Clarity! Explore device zoom settings for a closer, personalized view. ";
        state.active.menu = [{ icon: "info", title: "info" }];
      } else if (payload === "fps") {
        state.active.action = "your device's frames per second";
        state.active.icon = "info";
        state.active.title = "fps";
        state.active.information =
          "Device's performance with Frames Per Second (FPS) details. Coming soon to provide a smoother experience.";
        state.active.menu = [{ icon: "info", title: "info" }];
      } else if (payload === "fr") {
        state.active.action = "your device's frame rate";
        state.active.icon = "info";
        state.active.title = "fr";
        state.active.information =
          " device performance: Current Frame Rate - [FR] Frames Per Second. Encounter optimal responsiveness.";
        state.active.menu = [{ icon: "info", title: "info" }];
      } else if (payload === "mode") {
        state.active.action = "select mode";
        state.active.icon = "dark_mode";
        state.active.title = "mode";
        state.active.information =
          "Choose your view! Opt for light or dark mode in our menu for personalized visual comfort.";
        state.active.menu = [
          { icon: "dark_mode", title: "dark" },
          { icon: "light_mode", title: "light" },
        ];
      } else if (payload === "light") {
        payload = "setDashboard";
        var homeID = document.getElementById("init");
        homeID.classList.add("invert");
      } else if (payload === "dark") {
        payload = "setDashboard";
        var homeID = document.getElementById("init");
        homeID.classList.remove("invert");
      } else if (payload === "brightness") {
        state.active.action = "set brightness";
        state.active.icon = "settings_brightness";
        state.active.title = "brightness";
        state.active.information =
          "Adjust brightness to your liking. Find the perfect level for a comfortable viewing experience.";
        state.active.menu = [{ icon: "info", title: "info" }];
      } else if (payload === "background") {
        state.active.action = "set background";
        state.active.icon = "wallpaper";
        state.active.title = "background";
        state.active.information =
          "Customize your space effortlessly. Manage background page for a personalized and visually pleasing experience. Make it yours!";
        state.active.menu = [{ icon: "info", title: "info" }];
      } else if (payload === "fps") {
        state.active.action = "frames per second";
        state.active.icon = "settings_device";
        state.active.title = "";
        state.active.information =
          "Zoom In, Enhance Clarity! Explore device zoom settings for a closer, personalized view. ";
        state.active.menu = [
          { icon: "zoom_in", title: "zoom in" },
          { icon: "zoom_out", title: "zoom out" },
        ];
      } else if (payload === "engineering") {
        state.active.action = "engineering";
        state.active.icon = payload;
        state.active.title = "engineering";
        state.active.information =
          "Join the Innovation Team! Help shape the future – engineer with us. Collaborate, code, and create. Your skills can redefine user experiences. Let's build something extraordinary together!";
        state.active.menu = [
          { icon: "draw", title: "draw" },
          { icon: "architecture", title: "design" },
          { icon: "build", title: "build" },
        ];
      } else if (payload === "draw") {
        state.active.action = "draw your design";
        state.active.icon = "draw";
        state.active.title = "draw";
        state.active.information = "draw menu information";
        state.active.menu = [
          { icon: "bookmarks", title: "draw bookmarks" },
          { icon: "search", title: "search" },
          { icon: "draw", title: "live draw" },
          { icon: "draw", title: "new draw" },
          { icon: "draw", title: "load draw" },
        ];
      } else if (payload === "design") {
        state.active.action = "create design";
        state.active.icon = "architecture";
        state.active.title = "design";
        state.active.information =
          "Indulge in our menu's artistry. Choose from a palette of flavors and design your culinary masterpiece.";
        state.active.menu = [
          { icon: "draw", title: "live design" },
          { icon: "draw", title: "new design" },
          { icon: "draw", title: "load design" },
        ];
      } else if (payload === "build") {
        state.active.action = "build object";
        state.active.icon = "build";
        state.active.title = "build";
        state.active.information =
          "Unleash your creativity! Join us in building unique components that elevate user experiences.";
        state.active.menu = [
          { icon: "build", title: "component" },
          { icon: "build", title: "template" },
          { icon: "build", title: "layout" },
        ];
      } else if (payload === "component") {
        state.active.action = "build object";
        state.active.icon = "build";
        state.active.title = "components";
        state.active.information =
          "Craft unique components to enhance user interfaces. Contribute your design touch for exceptional design outcomes!";
        state.active.menu = [
          { icon: "build", title: "template" },
          { icon: "build", title: "layout" },
          { icon: "build", title: "element" },
        ];
      } else if (payload === "template") {
        state.active.action = "create a template";
        state.active.icon = "build";
        state.active.title = "template";
        state.active.information = "";
        state.active.menu = [
          { icon: "build", title: "container" },
          { icon: "build", title: "button" },
        ];
      } else if (payload === "element") {
        state.active.action = "create elements";
        state.active.icon = "build";
        state.active.title = "elements";
        state.active.information = "";
        state.active.menu = [{ icon: "build", title: "button" }];
      } else if (payload === "send") {
        state.active.action = "send";
        state.active.icon = payload;
        state.active.title = "send";
        state.active.information =
          "Message Sent! Your words on their way. Communication made simple. Stay connected and keep the conversation flowing. Send, share, and express with ease.";
        state.active.menu = [{ icon: "info", title: "info" }];
      } else if (payload === "media") {
        state.active.action = "media";
        state.active.icon = "perm_media";
        state.active.title = "media";
        state.active.information =
          " Immerse yourself in a world of sights and sounds. From videos that captivate to tunes that resonate, experience entertainment like never before. Let the journey begin!";
        state.active.menu = [
          { icon: "movie", title: "videos" },
          { icon: "photo", title: "pictures" },
          { icon: "music_note", title: "music" },
          { icon: "podcasts", title: "podcasts" },
          { icon: "auto_stories", title: "books" },
          { icon: "folder", title: "documents" },
        ];
      } else if (payload === "pictures") {
        state.active.action = "picture albums";
        state.active.icon = "photo";
        state.active.title = "photos";
        state.active.information =
          "Capture the moment! Dive into our Photos Menu to relive memories and organize your visual journey. Explore, share, and cherish every snapshot with ease.";
        state.active.menu = [
          { icon: "photo", title: "picture 1" },
          { icon: "photo", title: "picture 2" },
          { icon: "photo", title: "picture 3" },
          { icon: "photo", title: "picture 4" },
          { icon: "photo", title: "picture 5" },
          { icon: "photo", title: "picture 6" },
        ];
      } else if (payload === "music") {
        state.active.action = "play audio";
        state.active.icon = "music_note";
        state.active.title = "music";
        state.active.information =
          " Explore, discover, and let the music take you places. Your personal symphony awaits—hit play and let the rhythm guide your journey.";
        state.active.menu = [
          { icon: "search", title: "search" },
          { icon: "playlist_add", title: "playlists" },
          { icon: "music_note", title: "songs" },
        ];
      } else if (payload === "podcasts") {
        state.active.action = "listen to podcast";
        state.active.icon = "podcasts";
        state.active.title = "podcasts";
        state.active.information =
          "Embark on audio adventures, gain insights, and be inspired. Tune in to captivating stories and knowledge. Press play and let the podcast journey begin!";
        state.active.menu = [
          { icon: "podcasts", title: "podcast 1" },
          { icon: "podcasts", title: "podcast 2" },
          { icon: "podcasts", title: "podcast 3" },
          { icon: "podcasts", title: "podcast 4" },
        ];
      } else if (payload === "books") {
        state.active.action = "read books";
        state.active.icon = "auto_stories";
        state.active.title = "books";
        state.active.information =
          "Immerse yourself in the world of books. Explore captivating stories, gain knowledge, and let your imagination soar. Turn the page and start your next adventure! 🌟📖";
        state.active.menu = [
          { icon: "auto_stories", title: "book 1" },
          { icon: "auto_stories", title: "book 2" },
          { icon: "auto_stories", title: "book 3" },
          { icon: "auto_stories", title: "book 4" },
        ];
      } else if (payload === "documents") {
        state.active.action = "documents";
        state.active.icon = "folder";
        state.active.title = "documents";
        state.active.information =
          "Access essential information with ease! Explore our Documents Menu for organized and quick retrieval of important files. Simplify your workflow effortlessly.";
        state.active.menu = [
          { icon: "folder", title: "document 1" },
          { icon: "folder", title: "document 2" },
          { icon: "folder", title: "document 3" },
          { icon: "folder", title: "document 4" },
        ];
      } else if (payload === "games") {
        state.active.action = "start games";
        state.active.icon = "sports_esports";
        state.active.title = "games";
        state.active.information =
          "Level up your experience with thrilling adventures and captivating challenges. Dive into a world of excitement. Game on!";
        state.active.menu = [
          { icon: "search", title: "search" },
          { icon: "emoji_events", title: "events" },
          { icon: "star_rate", title: "achievements" },
          { icon: "leaderboard", title: "leaderboard" },
        ];
      } else if (payload === "calculate") {
        state.active.action = "do calculations";
        state.active.icon = "calculate";
        state.active.title = "calculate";
        state.active.information = "";
        state.active.menu = [
          { icon: "calculate", title: "standard" },
          { icon: "calculate", title: "scientific" },
          { icon: "calculate", title: "programming" },
        ];
      } else if (payload === "keyboard") {
        state.active.action = "input method is...";
        state.active.icon = "keyboard";
        state.active.title = "keyboard";
        state.active.information =
          "Select and configure Your Input Method! Seamlessly switch between input modes. Whether using a keyboard, mic, controller, tailor your experience effortlessly. Your input, your way!";
        state.active.menu = [
          { icon: "keyboard", title: "configure" },
          { icon: "mic", title: "mic" },
          { icon: "gamepad", title: "gamepad" },
        ];
      } else if (payload === "mic") {
        state.active.action = "input method is...";
        state.active.icon = "mic";
        state.active.title = "mic";
        state.active.information =
          "Select and configure Your Input Method! Seamlessly switch between input modes. Whether using a keyboard, mic, controller, tailor your experience effortlessly. Your input, your way!";
        state.active.menu = [
          { icon: "settings", title: "configure" },
          { icon: "keyboard", title: "keyboard" },
          { icon: "gamepad", title: "gamepad" },
        ];
      } else if (payload === "gamepad") {
        state.active.action = "configure";
        state.active.icon = "gamepad";
        state.active.title = "gamepad";
        state.active.information =
          "Controller Customization Unleashed! Personalize your gaming experience by configuring your controller settings. Map buttons, adjust sensitivity, and create your ideal setup. Level up your gameplay, your way. Dive into configuration now! ";
        state.active.menu = [
          { icon: "settings", title: "configure" },
          { icon: "keyboard", title: "keyboard" },
          { icon: "mic", title: "mic" },
        ];
      } else if (payload === "key") {
        state.active.action = "key";
        state.active.icon = "info";
        state.active.title = "key stroke";
        state.active.information = "";
        state.active.menu = [{ icon: "info", title: "info" }];
      } else if (payload === "apps") {
        state.active.icon = "apps";
        state.active.action = "play";
        state.active.title = "apps";
        state.active.information =
          "Apps Hub! Quickly access your favorites, organize effortlessly, and launch with ease. Your personalized app collection, ready when you are. Explore now! ";
        state.active.menu = [
          { icon: "settings_remote", title: "remote" },
          { icon: "public", title: "web" },
          { icon: "perm_media", title: "media" },
          { icon: "sports_esports", title: "games" },
          { icon: "calculate", title: "calculate" },
        ];
      } else if (payload === "web") {
        state.active.action = "browse the web";
        state.active.icon = "public";
        state.active.title = "web";
        state.active.information =
          "Uncover diverse content and seamless navigation. Explore a world of possibilities at your fingertips, making browsing a breeze.";
        state.active.menu = [{ icon: "bookmarks", title: "bookmarks" }];
      } else if (payload === "services") {
        state.active.action = "create or find service";
        state.active.icon = "miscellaneous_services";
        state.active.title = "services";
        state.active.information =
          "Services Hub Unveiled! Explore a one-stop destination for all your service needs. From support to premium features, discover a world of convenience. Elevate your experience with our Services Hub. Your satisfaction, our priority. ";
        state.active.menu = [
          { icon: "ads_click", title: "advertise" },
          { icon: "engineering", title: "engineering" },
          { icon: "health_and_safety", title: "health" },
          { icon: "work", title: "work" },
          { icon: "account_balance", title: "finances" },
          { icon: "apartment", title: "housing" },
          { icon: "map", title: "travel" },
        ];
      } else if (payload === "advertise") {
        state.active.action = "advertise a product or service";
        state.active.icon = "ads_click";
        state.active.title = "advertise";
        state.active.information =
          "Advertise with us and captivate your audience. Unlock new horizons for your brand. Impactful campaigns start here. Seize attention, make waves. Advertise with power!";
        state.active.menu = [{ icon: "info", title: "info" }];
      } else if (payload === "health") {
        state.active.action = "monitor your health";
        state.active.icon = "health_and_safety";
        state.active.title = "health";
        state.active.information =
          "Prioritize well-being with our health and safety menu. Nourish safely, savor responsibly, and thrive always!";
        state.active.menu = [
          { icon: "health_and_safety", title: "booking" },
          { icon: "health_and_safety", title: "offered" },
          { icon: "health_and_safety", title: "practitioners" },
          { icon: "health_and_safety", title: "records" },
          { icon: "health_and_safety", title: "resources" },
        ];
      } else if (payload === "work") {
        state.active.action = "find work";
        state.active.icon = "work";
        state.active.title = "work";
        state.active.information =
          "Embark on your career journey with us. Explore opportunities and find fulfilling work. Your next chapter awaits!";
        state.active.menu = [
          { icon: "work", title: "work 1" },
          { icon: "work", title: "work 2" },
          { icon: "work", title: "work 3" },
          { icon: "work", title: "work 4" },
        ];
      } else if (payload === "finances") {
        state.active.action = "finance market";
        state.active.icon = "account_balance";
        state.active.title = "finances";
        state.active.information =
          "Empower your financial journey! Explore smart solutions, plan for success, and achieve your financial goals confidently.";
        state.active.menu = [
          { icon: "account_balance", title: "finance 1" },
          { icon: "account_balance", title: "finance 2" },
          { icon: "account_balance", title: "finance 3" },
          { icon: "account_balance", title: "finance 4" },
        ];
      } else if (payload === "housing") {
        state.active.action = "housing";
        state.active.icon = "apartment";
        state.active.title = "housing";
        state.active.information =
          "Discover your ideal rental. Explore our listings to find housing that suits your needs. Welcome home!";
        state.active.menu = [
          { icon: "apartment", title: "house 1" },
          { icon: "apartment", title: "house 2" },
          { icon: "apartment", title: "house 3" },
          { icon: "apartment", title: "house 4" },
        ];
      } else if (payload === "travel") {
        state.active.action = "travel";
        state.active.icon = "map";
        state.active.title = "travel";
        state.active.information =
          "Uncover your next adventure! Explore diverse travel locations for an unforgettable journey. Start your exploration today!";
        state.active.menu = [
          { icon: "map", title: "taxi" },
          { icon: "map", title: "train" },
          { icon: "map", title: "bus" },
          { icon: "map", title: "flight" },
          { icon: "map", title: "Boat" },
        ];
      } else if (payload === "store") {
        state.active.action = "buy";
        state.active.icon = payload;
        state.active.title = "store";
        state.active.information =
          "Unlock savings and indulge! Redeem your voucher now for exclusive discounts. Treat yourself to a delightful experience.";
        state.active.menu = [
          { icon: "search", title: "search" },
          { icon: "shopping_bag", title: "shop" },
          { icon: "sell", title: "sale" },
          { icon: "local_shipping", title: "order" },
        ];
      } else if (payload === "market") {
        state.active.action = "find products and services";
        state.active.icon = "storefront";
        state.active.title = "market";
        state.active.information = "Find what you're looking for";
        state.active.menu = [
          { icon: "bookmarks", title: "my markets" },
          { icon: "search", title: "search market" },
          { icon: "add", title: "add market" },
          { icon: "update", title: "market updates" },
        ];
      } else if (payload === "my markets") {
        state.active.action = "your list of markets";
        state.active.icon = "storefront";
        state.active.title = "markets list";
        state.active.information = "your markets are found here";
        state.active.menu = [{ icon: "info", title: "markets" }];
      } else if (payload === "search markets") {
        state.active.action =
          "enter the product or service you would like to find";
        state.active.icon = "info";
        state.active.title = "market";
        state.active.information =
          "type the message in the search box, you will receive suggested products and services";
        state.active.menu = [{ icon: "info", title: "about market" }];
      } else if (payload === "favorites") {
        state.active.action = "select a favorite";
        state.active.icon = "bookmarks";
        state.active.title = "favorites";
        state.active.information = "Your favorites... ";
        state.active.menu = [{ icon: "info", title: "information" }];
      } else if (payload === "retailers") {
        state.active.action = "find retailers";
        state.active.icon = "storefront";
        state.active.title = "retailers";
        state.active.information =
          "Discover a world of convenience! Explore our retailer menu for a curated selection – your shopping destination!";
        state.active.menu = [
          { icon: "storefront", title: "retailer 1" },
          { icon: "storefront", title: "retailer 2" },
          { icon: "storefront", title: "retailer 3" },
          { icon: "storefront", title: "retailer ..." },
        ];
      } else if (payload === "order") {
        state.active.action = "find order services";
        state.active.icon = "local_shipping";
        state.active.title = "order";
        state.active.information =
          "Experience convenience at your doorstep! Our order services ensure swift, reliable, and contactless satisfaction. Order now!";
        state.active.menu = [
          { icon: "local_shipping", title: "item" },
          { icon: "local_shipping", title: "details" },
          { icon: "local_shipping", title: "cart" },
          { icon: "local_shipping", title: "address" },
          { icon: "local_shipping", title: "time" },
          { icon: "local_shipping", title: "support" },
          { icon: "local_shipping", title: "history" },
        ];
      } else if (payload === "redeem") {
        state.active.action = "redeem";
        state.active.icon = "redeem";
        state.active.title = "redeem";
        state.active.information =
          "Experience convenience at your doorstep! Our order services ensure swift, reliable, and contactless satisfaction. Order now!";
        state.active.menu = [{ icon: "info", title: "info" }];
      } else if (payload === "cart") {
        state.active.action = "manage cart";
        state.active.icon = "shopping_cart";
        state.active.title = "cart";
        state.active.menu = [
          { icon: "shopping_cart", title: "view" },
          { icon: "shopping_cart", title: "items" },
          { icon: "shopping_cart", title: "quantity" },
          { icon: "shopping_cart", title: "shopping" },
          { icon: "redeem", title: "coupon" },
          { icon: "shopping_cart", title: "shipping" },
          { icon: "shopping_cart", title: "cost" },
          { icon: "shopping_cart", title: "checkout" },
        ];
        state.active.information =
          "Explore your Cart menu to review, add, or remove items effortlessly. Your curated selection, ready for the perfect checkout experience. Shop smart, shop seamlessly!";
      } else if (payload === "shopping") {
        state.active.action = "shop for goods";
        state.active.icon = "shopping_bag";
        state.active.title = "shop";
        state.active.menu = [{ icon: "lunch_dining", title: "food" }];
        state.active.information =
          "From must-have products to premium services, discover a world of shopping joy. Elevate your experience – shop smarter, live better. Happy exploring!";
      } else if (payload.includes("edit_message:")) {
        let message = payload.replace("edit_message:", "");
        let index = state.messages.indexOf(message);
        state.active.action = "dashboard";
        state.active.icon = payload.split(":")[1];
        state.active.title = "messages";
        state.active.information = "Edit messages";
        state.icons.main = [
          "edit",
          "delete",
          "recommend",
          "attachment",
          "play_circle",
        ];
        state.placeholder = state.messages[index];
      } else if (payload === "edit") {
        state.active.action = "edit";
        state.active.icon = payload;
        state.active.title = "edit";
        state.active.information =
          "Write it the way you would've responsed, then subit your suggestion, it will go up for review, and if approved, will earn you coins";
        state.active.menu = [
          { icon: "remove", title: "remove" },
          { icon: "add", title: "add" },
        ];
      } else if (payload === "delete") {
        state.active.action = "delete";
        state.active.icon = payload;
        state.active.title = "delete";
        state.active.information = "delete message";
        state.placeholder += " : delete";
      } else if (payload === "recommend") {
        state.active.action = "back_arrow";
        state.active.icon = payload;
        state.active.title = "recommend";
        state.active.information = "recommend content";
        state.placeholder += " : recommend";
      } else if (payload === "attachment") {
        state.active.action = "back_arrow";
        state.active.icon = payload;
        state.active.title = "attachment";
        state.active.information = "attach a file or document";
        state.placeholder += " : attachment";
      } else if (payload === "play_circle") {
        state.active.action = "play_circle";
        state.active.icon = payload;
        state.active.title = "run";
        state.active.information = "Associate words with UI";
        state.active.menu = [];
      } else if (payload === "simulator") {
        state.active.action = "simulator";
        state.active.icon = payload;
        state.active.title = "simulator";
        state.active.information = "REMEARTIN Simulator";
      } else if (payload === "emulator") {
        state.active.action = "emulator";
        state.active.icon = payload;
        state.active.title = "emulator";
        state.active.information = "REMEARTIN Emulator";
      } else if (payload === "remote") {
        state.active.action = "remote control";
        state.active.icon = "settings_remote";
        state.active.title = "remote";
        state.active.information =
          "Embrace the future of control! Experience seamless automation with our smart control feature. Effortlessly manage and customize your environment at your fingertips.";
        state.active.menu = [
          { icon: "settings_remote", title: "house" },
          { icon: "settings_remote", title: "car" },
          { icon: "settings_remote", title: "computer" },
          { icon: "settings_remote", title: "tv" },
          { icon: "settings_remote", title: "console" },
        ];
      } else if (payload === "browser") {
        state.active.action = "browser";
        state.active.icon = "public";
        state.active.title = "browser";
        state.active.information =
          "Effortlessly find what you need. Browse faster, smarter, and explore the web seamlessly. ";
        state.active.menu = [{ icon: "bookmarks", title: "bookmarks" }];
      } else if (payload === "bookmarks") {
        state.active.action = "bookmarks";
        state.active.icon = "bookmarks";
        state.active.title = "bookmarks";
        state.active.information =
          "Save your favorite webpages for instant access to the content that sparks your interest. Enjoy browsing";
        state.active.menu = [
          { icon: "bookmarks", title: "bookmark 1" },
          { icon: "bookmarks", title: "bookmark 2" },
          { icon: "bookmarks", title: "bookmark 3" },
          { icon: "bookmarks", title: "bookmark 4" },
        ];
      } else if (payload === "media") {
        state.active.action = "media";
        state.active.icon = payload;
        state.active.title = "media";
        state.active.information =
          " Immerse yourself in a world of sights and sounds. From videos that captivate to tunes that resonate, experience entertainment like never before. Let the journey begin! ";
      } else if (payload === "videos") {
        state.active.action = "play videos";
        state.active.icon = "movie";
        state.active.title = "videos";
        state.active.information = "video player";
        state.active.menu = [
          { icon: "movie", title: "video 1" },
          { icon: "movie", title: "video 2" },
          { icon: "movie", title: "video 3" },
          { icon: "movie", title: "video 4" },
        ];
      } else if (payload === "document") {
        state.active.action = "document";
        state.active.icon = payload;
        state.active.title = "document";
        state.active.information = "document reader";
        state.active.menu = [
          { icon: "documents", title: "document 1" },
          { icon: "documents", title: "document 2" },
          { icon: "api", title: "document 3" },
        ];
      } else if (payload === "api") {
        state.active.action = "api";
        state.active.icon = payload;
        state.active.title = "api";
        state.active.information =
          " API Options Explored! Dive into a realm of possibilities with our versatile API offerings. From seamless integrations to tailored solutions, empower your projects with our diverse API options. Your data, your rules. Explore the API frontier now!";
        state.active.menu = [
          { icon: "api", title: "db" },
          { icon: "api", title: "interface" },
          { icon: "api", title: "ai" },
        ];
      } else if (payload === "db") {
        state.active.action = "db";
        state.active.icon = "api";
        state.active.title = "CRUD";
        state.active.information =
          "CRUD API Central! Create, Read, Update, Delete – Seamless data control. Your API, your commands. Explore CRUD functionalities effortlessly in our menu!";
        state.active.menu = [
          { icon: "add", title: "create" },
          { icon: "edit", title: "edit" },
          { icon: "update", title: "update" },
          { icon: "remove", title: "delete" },
        ];
      } else if (payload === "nlp") {
        state.active.action = "nlp";
        state.active.icon = payload;
        state.active.title = "nlp";
        state.active.information = "REMEARTIN nlp";
      } else {
        state.active.action = payload;
        state.active.icon = "info";
        state.active.title = payload;
        state.active.information =
          "Up Coming Feature: Exciting times ahead! We're thrilled to announce an innovative new feature that will take your experience to the next level. Stay tuned for updates as we prepare to unveil this game-changing addition to our app. Your anticipation fuels our excitement!";
        state.active.menu = [{ icon: "info", title: "information" }];
      }
      // set dashboard
      if (payload === "setDashboard") {
        state.active.action = "session";
        state.active.icon = "dashboard";
        state.active.title = "dashboard";
        state.active.information =
          "Unleash the power of A.I. at your fingertips. Intelligent, intuitive, and ready to revolutionize your experience. Let's redefine the future together. Select one of the options to get started.";
        state.active.menu = [
          { icon: "person", title: "account" },
          { icon: "apps", title: "apps" },
          { icon: "miscellaneous_services", title: "services" },
          { icon: "shopping_bag", title: "store" },
          { icon: "settings", title: "settings" },
        ];
      }
    },
  },
});
