<template>
  <div class="app bgr-img">
    <Init />
  </div>
</template>

<script>

// >>>>>>>>>>>>>>>>>>> RESIZE JS >>>>>>>>>>>>>>>>>>>>
// Function to update --vh variable based on window height
function updateVH() {
  // Set --vh variable to 1% of the updated window's inner height
  document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`);
}

// Initial update
updateVH();

// Update on window resize or when the virtual keyboard is toggled
window.addEventListener('resize', updateVH);
window.addEventListener('keyboardDidShow', updateVH);
window.addEventListener('keyboardDidHide', updateVH);
// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

import Init from "../src/cui/template/home/Init.vue";

export default {

  name: "App",

  components: {
    // Theme,
    Init,
  },

  computed: {
    cui() {
      return this.$store.state.cui;
    },
  }

};
</script>

<style>
/* IMPORTS */
@font-face {
  font-family: FontFamily;
  src: url("./assets/fonts/Montserrat/Montserrat-Medium.ttf");
}

/* ------------------ */
/* Root-------------- */
/* ------------------ */

:root {
  --vw: 100vw;
  --vh: 1vh;


  /* --line-height: calc(var(--fs)/36); */

  --clr-primary: rgba(0, 0, 0, 0.658);
  --clr-secondary: rgba(255, 255, 255, .45);
  --clr-secondary-2: rgba(99, 226, 230, 0.72);

  --clr-success: rgba(83, 255, 83, 0.63);
  --clr-caution: rgba(255, 166, 0, 0.63);
  --clr-danger: rgba(255, 117, 117, 0.63);
  --clr-highlight: rgba(99, 226, 230, 0.63);

  /* --clr-secondary: silver; */
  --clr-secondary-100: #4e6163f3;
  --clr-secondary-200: #4e61631a;
  --clr-secondary-300: #84b9bf3f;
  --clr-secondary-400: #6f7475ce;
  --clr-tertiary: rgba(72, 198, 243, 0.81);
  --clr-light: #e0e0e0;
  --clr-dark: #031624;

  /* base font size*/

  /*  */
  --fs: calc(100vw*0.05/1.44);
  /* --fs: 1rem; */
  /*  */
  --fs-h1: calc(var(--fs)*1.44*(1.618/1.44));
  --fs-h2: calc(var(--fs)*1.44*(1.618/2.34));
  --fs-h3: calc(var(--fs)*1.44/(1.618*1.17));
  --fs-h4: calc(var(--fs)*1.44/(1.618*1.44));
  --fs-h5: calc(var(--fs)*1.44/(1.618*1.8));
  --fs-h6: calc(var(--fs)*1.44/(1.618*2));
  --fs-h7: calc(var(--fs)/1.62/1.62);

  --fs-icon: var(--fs-h2);
  --fs-icon-medium:var(--fs-h2) ;
  --fs-icon-small: var(--fs-h4);

  /* font-family */
  --ff-primary: FontFamily, sans-serif;

  /* BORDER */
  --border: calc(var(--fs) / 9) solid rgba(255, 255, 255, 0.18);
  --border-2: 1px solid var(--clr-secondary);
  --dotted: calc(var(--fs) / 63) dotted rgba(197, 197, 197, 0.63);
  --underline: calc(var(--fs) / 9) solid rgba(133, 217, 223, 0.27);

  /* RADIUS */
  /*  */
  --border-radius: calc(var(--fs) /1.8 );

  --border-radius-top: var(--border-radius) var(--border-radius) 0 0;
  --border-radius-bottom: 0 0 var(--border-radius) var(--border-radius);

  /* spacing */

  --gap: calc(var(--fs) / 3.6);
  --gap-inline: calc(var(--fs) /1);
  --padding-div: calc(var(--fs) / 1.8) calc(var(--fs) *3.6);
  --padding-div-small: calc(var(--fs) / 10) calc(var(--fs) /2);
  --padding: calc(var(--fs)*3);
  --padding-small: calc(var(--fs) / 3) calc(var(--fs) / 6);
  --padding-big: calc(var(--fs) * 6);
  --padding-inline: calc(var(--fs) / 36) calc(var(--fs) / 1.44);
  --padding-bottem: calc(var(--fs));
  --padding-panel: calc(var(--fs) / 4) calc(var(--fs) * 1.25);
  /*  */
  --margin: calc(var(--fs) / 6.3);
  --margin-right: calc(var(--fs) / 3.6);
  --margin-big: calc(var(--fs) / 1);
  --margin-bottom: calc(var(--fs) / 1.8);

  /* UTILITIES */
  --box-shadow: 1px 3px 6px rgba(163, 185, 196, 0.18);
  --text-shadow: 1px 3px 6px rgba(0, 0, 0, 1);

  --uppercase: uppercase;
  --capitalize: capitalize;

  --bgr-light: rgba(255, 255, 255, 0.18);
  --bgr-dark: rgba(3, 23, 38, 0.18);
  /*  */

  /* white scale */
  --gradient-clr: rgba(2, 15, 26, 0.54);
  --gradient-clr-2: rgb(2, 15, 26, 0.45);
  --gradient-clr-3: rgb(2, 15, 26, 0.54);

  --gradient: linear-gradient(to left,
      var(--gradient-clr) 30%,
      var(--gradient-clr-2) 40%,
      var(--gradient-clr-3) 60%);

  /* dark scale */
  --gradient-2-clr: rgba(2, 15, 26, 0.54);
  --gradient-2-clr-2: rgb(2, 15, 26, 0.45);
  --gradient-2-clr-3: rgb(2, 15, 26, 0.54);

  --gradient-2: linear-gradient(to left,
      var(--gradient-2-clr) 100%,
      var(--gradient-2-clr-2) 100%,
      var(--gradient-2-clr-3) 100%);

  --gradient-3: linear-gradient(to left,
      grey 30%,
      grey 40%,
      grey 60%);

  /* TEMPLATES */
  --column-2-1: repeat(2, 1fr);
  /* ANIMATIONS */
  --transition: 0s;
}

/* ------------------ */
/* Resets-------------- */
/* ------------------ */

/* initiale html */
html {
  scroll-behavior: smooth;
  /* font-size: calc(var(--fs)/3); */
  font-size: var(--fs);
}

*,
::before,
::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: var(--ff-primary), sans-serif;
  font-weight: 400;
  letter-spacing: 0.018rem;
  text-shadow: var(--text-shadow);
  color: var(--clr-light);
  /* line-height: 0; */
}

/* ------------------ */
/* Typography-------------- */
/* ------------------ */
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
small {
  padding: 0;
  margin: 0;
  transition: var(--transition);
}

/* custom a-scale 1.26 */
.pointer {
  cursor: pointer;
}

.pointer-none {
  cursor: grab;
}

.pointer:hover {
  /* background-image: var(--gradient); */
  /* width: 100%; */

}

.pointer:focus {
  /* background-image: var(--gradient-2); */
  /* width: 100%; */
  /* box-shadow: var(--box-shadow); */
}



h1 {
  font-size: var(--fs-h1);
}

h2 {
  font-size: var(--fs-h2);
}

h3 {
  font-size: var(--fs-h3);
}

h4 {
  font-size: var(--fs-h4);
}

h5 {
  font-size: var(--fs-h5);
}

h6 {
  font-size: var(--fs-h6);
}

i {
  width: fit-content;
  height: fit-content;
  padding: calc(var(--fs)/2.7) !important;
  margin: calc(var(--fs)/18) !important;
  
  /* font-size: 1.125rem !important; */
  font-weight: 100;
  /* text-decoration: var(--underline) !important; */
  /* border-left: var(--border); */
  /* border-bottom : var(--border); */
  border-radius: 50%;
  /* box-shadow: var(--box-shadow); */
  background-image: var(--gradient-2);
  transition: var(--transition);
  text-shadow: var(--text-shadow);

}

.fs-icon i {
  padding: 0;
  padding-left: 0rem;
  font-size: var(--fs-icon);
  color: var(--clr-caution);
  fill: 0;
  text-decoration: var(--underline) !important;
  border-radius: 50%;
}

.fs-icon-small i {
  padding: .0rem !important;
  margin: 0 !important;
  padding-left: 0rem;
  font-size: var(--fs-icon-small);
  fill: 0;
  text-decoration: var(--underline) !important;
  color: var(--clr-secondary);
  background: 0;
  box-shadow: 0;
  border-radius: 50%;
}

.fs-icon-medium i {
  /* padding: .63rem; */
  /* padding-left: 0rem; */
  text-decoration: var(--underline) !important;
  color: var(--clr-secondary);
  font-size: var(--fs-icon-medium);
  background: transparent;
  box-shadow: 0 !important;
  border-radius: 50%;
}


.material-icon {
  font-weight: 100;
}

.i-settings i {
  padding: calc(var(--fs) / 3.6);
  margin: calc(var(--fs) / 4.5) calc(var(--fs) / 2.7);
  color: var(--clr-tertiary);
  font-size: calc(var(--fs-icon) / 1.8);

  /* border: 1px dotted white; */
}

.button {
  display: inline-block;
  padding: 15px 25px;
  font-size: 24px;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: #4caf50;
  border: none;
  border-radius: 15px;
  box-shadow: 0 9px #999;
}

.button:hover {
  background-color: #3e8e41;
}

.button:active {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

p {
  font-size: calc(var(--fs) * 0.63);
}

.p-gap {
  line-height: calc(var(--fs) * 1);
}

span {
  font-size: var(--fs-h6);
  line-height: auto;
}

.line-height {
  line-height: var(--line-height);
}

.img {
  height: 8vh;
  display: flex;
}


/* ------------------ */
/* components--------- */
/* ------------------ */
.app {
  height: var(--vh);
  width: var(--vw);
  position: relative;
  /* Needed for absolute positioning of child */
  /* background-image: url("../src/assets/images/co-bgr.jpg"); */
  /* background: rgb(45, 77, 145); */
  background-size: cover;
  filter: grayscale(36%);
}

.height-100p {
  height: 100%;
}

/* ------------------ */
/* utilities--------- */
/* ------------------ */

.fs-h1 {
  font-size: var(--fs-h1);
}

.fs-h2 {
  font-size: var(--fs-h2);
}

.fs-h3 {
  font-size: var(--fs-h3);
}

.fs-h4 {
  font-size: var(--fs-h4);
}

.fs-h5 {
  font-size: var(--fs-h5);
}

.fs-h6 {
  font-size: var(--fs-h6);
}

/*  */

.grid {
  height: 100%;
  display: grid;
}

.grid-row-2 {
  height: 100%;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
}

.grid-col-a1 {
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
}

.grid-col-6-3 {
  display: grid;
  grid-template-columns: .639fr .369fr;
}

.grid-col-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.grid-col-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.grid-col-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.grid-col-6 {
  display: grid;
  grid-template-rows: repeat(6, 1fr);
}

.columns-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--gap);
}

.grid-row-6 {
  display: grid;
  grid-template-rows: repeat(6, 1fr);
}

.grid-2>div {
  border: var(--dotted);
  padding: 3%;
  margin: 1px;
}

.column {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  display: flex;
  justify-content: space-around;
}

.overflow {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-div>div {
  overflow: auto;
}

.box-shadow {
  box-shadow: var(--box-shadow);
}

.gradient {
  background-image: var(--gradient);
}

.gradient-2 {
  background-image: var(--gradient-2);
}

.frosted-gradient {
  background: linear-gradient(to bottom,
      #1e5e9244 0%,
      #2989d82f 81%,
      #207bca8f 90%,
      #7dbae85b 72%);
  /* filter: blur(5px); */
  /* -webkit-filter: blur(5px); */
}

.bgr-tertiary {
  background-color: var(--clr-tertiary);
}

.bg-dark {
  background-color: var(--clr-dark);
}

.item {
  width: fit-content;
  padding: var(--padding-inline);
  margin: calc(var(--fs) / 8);
  background-image: var(--gradient-2);
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-center {
  height: calc(100%);
  display: grid;
  grid-template-rows: 1fr 0.25fr 0.125fr;
}

.label {
  /* display: none; */
  font-size: var(--fs-h5);
  /* text-transform: uppercase; */
  border-bottom: 1px solid lightgray;
  /* border-radius: var(--border-radius); */
  /* background: var(--gradient-2); */

  color: var(--clr-dark);
  padding: var(--padding-inline);
}

.label-small {
  font-size: var(--fs-h6);
  border-radius: var(--border-radius);
  background: var(--gradient-2);
  color: var(--clr-light);
  padding: var(--padding-inline);
}


.characters-54 {
  max-width: 18ch;
}

/* feedback colors */
.clr-success {
  color: var(--clr-success);
}

.clr-caution {
  color: var(--clr-caution);
}

.clr-danger {
  color: var(--clr-danger);
}

.clr-highlight {
  color: var(--clr-highlight);
}

.bgr-img {
  width: 100%;
  background-image: url("../../client/src/assets/images/co-bgr.jpg");
  background-size: cover;
  height: auto;  
}

.bgr-img-2 {
  width: 100%;
  background-image: url("../../client/src/assets/images/cui.jpg");
  background-size: cover;
  height: auto;  
}

.bgr-img-3 {
  width: 100%;
  background-image: url("../../client/src/assets/images/agi.jpg");
  background-size: cover;
  height: auto;  
}

  .invert{
    filter: invert();
  }

/* colors */

.clr-primary {
  color: var(--clr-primary);
}

.clr-light {
  color: var(--clr-light);
}

.clr-dark {
  color: var(--clr-dark);
}

.clr-secondary {
  color: var(--clr-secondary);
}

.clr-secondary-2 {
  color: var(--clr-secondary-2);
}

.clr-tertiary {
  color: var(--clr-tertiary);
}

.clr-orange {
  color: orange;
}

.bgr-dark {
  background-color: var(--clr-dark);
}

.bgr-light {
  background-color: var(--clr-light);
}

.underline {
  border-bottom: var(--underline);
}

.spacer {
  margin-bottom: calc(var(--fs)/2.25);
}

.padding {
  padding: var(--padding);
}

.padding-right {
  padding-right: calc(var(--fs) / 1);
}

.padding-right-small {
  padding-right: .18rem;
}

.padding-small {
  padding: var(--padding-small);
}

.padding-inline-big {
  padding-inline: calc(var(--padding-inline) * 6.3);
}

.padding-x {
  padding: 0 var(--padding-big);
}

.padding-big {
  padding: var(--padding-big);
}

.padding-inline {
  padding: var(--padding-inline);
}

.padding-bottom {
  padding: var(--padding-bottom);
}

.gap {
  gap: var(--gap);
}

.gap-inline {
  gap: var(--gap-inline);
}

.gap-small {
  gap: calc(var(--gap) / 2);
}

.margin {
  margin: var(--margin);
}

.margin-right {
  margin-inline: var(--margin-right);
}


.margin-big {
  margin: var(--margin-big);
}

.margin-div {
  margin: 0;
}

.margin-div>div {
  margin: var(--margin);
}

.margin-bottom {
  margin-bottom: var(--margin-bottom);
}

.border {
  /* border: var(--border); */
  box-shadow:  1px 3px 6px rgba(133, 192, 219, 0.219);
}

.border-div>div {
  border: var(--border);
}

.border-tertiary {
  border: 1px solid var(--clr-tertiary);
}

.highlight {
  border: 1px solid white;
}

.bordered {
  border: 1px solid rgba(255, 255, 255, 0.36);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

.dotted {
  border: var(--dotted);
}

.padding-9 {
  padding: 0 9%;
}

.dotted-div>div {
  border: var(--dotted)
}

.dotted-active {
  border: 0;
}

.dotted-active>div {
  border: 1px dotted rgba(211, 211, 211, 0.205);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

.underline-div {
  border: 0;
}

.underline-div>div {
  border: var(--underline);
}

.column-4 {
  height: 40%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.column-21 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.column-121 {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
}

.padding-div>div {
  padding: var(--padding-div);
}

.padding-div-small>div {
  padding: calc(var(--padding-div-small));
}

.radius-div {
  border: 0;
}

.radius-div>div {
  border-radius: var(--border-radius);
}

.hide {
  display: none;
}

.split-column {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.split-column>div {
  padding: var(--fs);
}

.split-row {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
}

.split-row {
  box-shadow: none;
}

.row-1-3-2 {
  display: grid;
  grid-template-rows: auto fr 1fr 3fr;
}

.row-11 {
  display: grid;
  grid-template-rows: 1fr 1fr;
}

.rows-13a {
  display: grid;
  grid-template-rows: 1fr 3fr auto;
}

.split-column-1-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--gap);
}

.split-column-1-2>div {
  /* box-shadow: var(--box-shadow); */
  padding: var(--padding-panel);
  border-radius: var(--border-radius);
  /* background: var(--bgr-light); */
}

.bgr-light {
  background: var(--bgr-light);
}

.border-radius {
  border-radius: var(--border-radius);
}

.border-radius-div {
  border-radius: 0;
}

.border-radius-div>div {
  border-radius: var(--border-radius);
}

.border-radius-top {
  border-radius: var(--border-radius-top);
}

.border-radius-bottom {
  border-radius: var(--border-radius-bottom);
}

.capitalize {
  text-transform: var(--capitalize);
}

.uppercase {
  text-transform: var(--uppercase);
}

/* ------------------ */
/* Elements-------- */
/* ------------------ */

.column-2-1 {
  display: grid;
  grid-template-columns: var(--column-2-1);
  gap: var(--fs);
}

.column-2-1>div {
  padding: var(--fs);
}

.heading {
  width: fit-content;
  padding: var(--padding-inline);
  /* border: var(--border); */
  /* border-radius: var(--border-radius); */
  /* box-shadow: var(--box-shadow); */
}

.snall-message {
  display: flex;
  width: fit-content;
  padding: 0.09rem 0.36rem;
  margin: 0.18rem;
  border: var(--border);
  /* border-top: 1px solid rgba(211, 211, 211, 0.63); */
  border-radius: var(--border-radius);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  background-image: var(--gradient);
}

.dot {
  background: var(--bgr-light);
  width: 3px;
  height: 3px;
  border-radius: var(--border-radius);
  margin: 1px;
}

/* ------------------ */
/* Components-------- */
/* ------------------ */

.box {
  height: 100%;
  width: 100%;
  box-shadow: var(--box-shadow);
}

.cards {
  height: 100%;
  width: 100%;
  padding: calc(var(--fs) / 4);
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(2, 1fr);
}

.cards>div {
  height: 100%;
  border: var(--border);
  border-radius: var(--border-radius);
  /* padding: 0.5rem 1rem; */
  background-image: var(--gradient);
  box-shadow: var(--box-shadow);
  color: var(--clr-light);
}

.title-mini {
  background-image: var(--gradient);
  border-radius: var(--border-radius);
  color: var(--clr-secondary);
  text-transform: uppercase;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  /* height: calc(var(--fs) * 12); */
  /* width: calc(var(--fs) * 7); */
  margin: auto;
  border-radius: var(--border-radius);
  transition: var(--transition);
}

.card>div {
  padding: var(--padding-inline);
}

.table {
  display: grid;
}

.bold {
  font-weight: bold;
}


/* ------------------ */
/* Frames------------ */
/* ------------------ */

.dashboard-left {
  display: grid;
  grid-template-rows: 1fr 0.5fr;
}

.dashboard-left>div {
  overflow: auto;
  /* border: var(--border); */
  /* border-radius: var(--border-radius); */
  /* box-shadow: var(--box-shadow); */
}

.dashboard-right {
  display: grid;
  grid-template-rows: auto 2fr 1fr;
}

.bordered {
  margin: 0.18rem;
}

.row-a36 {
  display: grid;
  grid-template-rows: auto 5fr 3fr;
}

.column-221 {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
}

.bordered>div {
  border: 1px dotted black;
}

.split {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.list {
  display: flex;
  flex-wrap: wrap;
}

.list>div {
  min-width: fit-content;
}



.player {
  height: 100%;
  display: grid;
}

.player>div {
  /* border: var(--border); */
  padding: var(--padding);
}

.time {
  display: grid;
}

.fit-content {
  height: fit-content;
  width: fit-content;
}

/* ------------------ */
/* Animations------------- */
/* ------------------ */
/* pulse */
.pulse {
  animation: pulse 3.6s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    background-color: rgba(255, 255, 255, 0.36);
  }
}

/* pulse gradient */
.gradient-bar {
  height: 3px;
  background: linear-gradient(to right, transparent, black, white);
  background-size: 100% 100%;
  animation: circleAnimation 9000ms linear infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 0%;
  }

  100% {
    background-position: 100% 0%;
  }
}


/* circleAnimation */
@keyframes circleAnimation {
  0% {
    background-color: rgba(255, 255, 255, 0);
    /* Transparent at the beginning */
  }

  63% {
    background-color: rgba(255, 255, 255, 1);
    /* Fully white at the end */
  }
}

.running-div {
  height: .009rem;
  grid-template-columns: repeat(10, 1fr);
  overflow: auto;
}

.circle {
  height: .18rem;
  background-color: rgba(255, 255, 255, 0);
  /* Initial transparency */
  position: absolute;
  animation: circleAnimation 3.6s linear infinite;
  /* Adjust the time frame as needed */
}

.circle:nth-child(2) {
  left: 20px;
  animation-delay: 0.3s;
}

.circle:nth-child(3) {
  left: 40px;
  animation-delay: 0.6s;
}

.circle:nth-child(4) {
  left: 60px;
  animation-delay: 0.9s;
}

.circle:nth-child(5) {
  left: 80px;
  animation-delay: 1.2s;
}

.circle:nth-child(6) {
  left: 100px;
  animation-delay: 1.5s;
}

.circle:nth-child(7) {
  left: 120px;
  animation-delay: 1.8s;
}

.circle:nth-child(8) {
  left: 140px;
  animation-delay: 2.1s;
}

.circle:nth-child(9) {
  left: 160px;
  animation-delay: 2.4s;
}

/* width */
::-webkit-scrollbar {
  width: calc(var(--fs)/14);
  height: calc(var(--fs)/14);
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(128, 128, 128, 0.233);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(128, 128, 128, 0.233);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(128, 128, 128, 0.233);
}

/* placeholder text color */
::placeholder {
  color: var(--clr-secondary); /* Change the color to blue */
}

@media screen and (orientation: portrait){
  :root{
    --gap: calc(var(--fs)/1);
  }

  .init{
    padding: 9% 1%;
  }

  .init-content{
    height: 100%;
    grid-template-rows: .63fr .36fr auto;
  }

  .init-display{
    grid-template-rows: .63fr .36fr;
  } 

  .message-item{
    flex-direction: column;
  }
};

@media screen and (orientation: landscape){


  .init-display-slides{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }

  .init {
    padding-inline: 18%;
  }

  .menu{
    /* display: none; */
  }
};

/* Styles for devices with screens smaller than 768px (typical tablets and smartphones) */
@media only screen and (max-width: 2700px) {

  /* Insert your mobile-specific styles here */
  :root {
    --fs: calc(100vw*0.05/3.33);
  }

  /* Example of hiding an element on mobile */
  .hide-on-mobile {
    display: none;
  }
}

/* Styles for devices with screens smaller than 768px (typical tablets and smartphones) */
@media only screen and (max-width: 1620px) {

  /* Insert your mobile-specific styles here */
  :root {
    --fs: calc(100vw*0.05/3.6);
    --padding-div: calc(var(--fs) / 18) calc(var(--fs) / 3);
    --padding-div-small: calc(var(--fs) / 18) calc(var(--fs) /3);
    --padding: calc(var(--fs) / 18) calc(var(--fs) / 3);
  }

  .init{
    padding-inline: 9%;
  }



  /* Example of hiding an element on mobile */
  .hide-on-mobile {
    display: none;
  }
}

/* Styles for devices with screens smaller than 768px (typical tablets and smartphones) */
@media only screen and (max-width: 963px) {

  /* Insert your mobile-specific styles here */
  :root {
    --fs: calc(100vw*0.05/3.6);
  }

  /* Example of hiding an element on mobile */
  .hide-on-mobile {
    display: none;
  }
}

/* Styles for devices with screens smaller than 768px (typical tablets and smartphones) */
@media only screen and (max-width: 810px) {

  /* Insert your mobile-specific styles here */
  :root {
    --fs: calc(100vw*0.05/1.8);
  }

  /* Example of hiding an element on mobile */
  .hide-on-mobile {
    display: none;
  }

  .init{
    padding-inline: 6%;
  }
}

/* Styles for devices with screens smaller than 768px (typical tablets and smartphones) */
@media only screen and (max-width: 640px) {

/* Insert your mobile-specific styles here */
:root {
  --fs: calc(100vw*0.05/2.43);
}

/* Example of hiding an element on mobile */
.hide-on-mobile {
  display: none;
}

.init{
  padding-inline: 9%;
}
}

/* Styles for devices with screens smaller than 480px (typical smartphones) */
@media only screen and (max-width: 479px) {

  /* Insert your styles for smaller screens here */
  :root {
    --fs: calc(100vw*0.05/1.44);
  }

  /* Example of hiding a different element on smaller screens */
  .hide-on-small-mobile {
    display: none;
  }

  .init{
    padding-inline: 3%;
  }
}

/* Styles for devices with screens smaller than 768px (typical tablets and smartphones) */
@media only screen and (max-width: 180px) {

  /* Insert your mobile-specific styles here */
  :root {
    --fs: calc(100vw*0.05);
  }

  /* Example of hiding an element on mobile */
  .hide-on-mobile {
    display: none;
  }
}
</style>
